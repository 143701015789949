import React from 'react';

import {ArticleList, ArticleListBySlugs} from './article-list';
import {Image} from "../../components/image";
import Button from "../../components/button";

export const ArticleBodyComponent = ({article}) => {
    return (
        <section className="">
            <div className="container article-view">
                <article
                    className={' md:max-w-66'}
                    dangerouslySetInnerHTML={{__html: article.html}}
                />
            </div>
        </section>
    );
};

export const ArticleHeadingComponent = ({article}) => {
    return (
        <>
            <section className={"container to-logo__blue"}>
                <div className={"background-title-container background-title-container__left as-title as-marquee"}>
                    <div className={"background-title"}>
                        <span>Blog. Blog. Blog. Blog. Blog. Blog. Blog. Blog. Blog.&nbsp;</span>
                        <span>Blog. Blog. Blog. Blog. Blog. Blog. Blog. Blog. Blog.&nbsp;</span>
                    </div>
                </div>
                <h1 className={"h2 md:max-w-66"}>{article.frontmatter.title}</h1>
                <p className="resume md:max-w-66">
                    {article.frontmatter.description}
                </p>
            </section>
            <section
                className="section-white"
            >
                <div className="container">
                    <div className={"news-thumb md:max-w-66"}>
                        <Image
                            src={article.frontmatter.thumbnail}
                            alt={article.frontmatter.title}
                        />

                        <div className={"news-thumb-tags"}>
                            <div className={"tag"}>{article.frontmatter.date}</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export const ArticleSocialLinksComponent = ({article, pageContext}) => {
    let url = `${pageContext.siteMetadata.siteUrl}/${article.fields.path}`;
    return (
        <section className={'blue-section mb-24 share-blog'}>
            <div className="container">
                <div>
                    <strong className={"share-label"}>Discuter</strong>
                    <h2 className={"h3"}>{article.frontmatter.cta ?? "Vous voulez un site beau, optimisé & performant ?"}</h2>
                    <Button name={"Contactez-moi"} link={"/contact/"}/>
                </div>
                <div>
                    {article.frontmatter.sources && (
                        <>
                            <strong className={"share-label"}>Sources</strong>
                            <ul>
                                {article.frontmatter.sources.map((v, i) => <li key={i}>
                                    <a href={v} key={i} target={"_blank"} rel={"noreferrer"}>{v}</a>
                                </li>)}
                            </ul>
                        </>
                    )}<br/>
                    <strong className={"share-label"}>Partager</strong>
                    <div className={"btn-list"}>
                        <a className={"btn-circle btn-secondary btn-icon btn-linkedin"} aria-label={"Linkedin"} target={"_blank"} rel={"noreferrer"} href={"https://www.linkedin.com/sharing/share-offsite/?url=" +url}></a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export const ArticleRelatedComponent = ({article, pageContext}) => {
    let mainCatSlug = article.frontmatter.mainCategory ??
        article.fields.categories[0] ?? '';
    let parentLink = mainCatSlug ? `/blog/${mainCatSlug}/` : `/blog/`;
    return (
        <section className={''}>
            <div className="container">
                <h2>Ces articles<br/>
                    pourraient vous intéresser</h2>
                {article.frontmatter.related_articles ?
                    <ArticleListBySlugs
                        articleSlugs={article.frontmatter.related_articles}/> :
                    <ArticleList
                        articles={[pageContext.previous, pageContext.next].filter(
                            Boolean)}/>
                }
                <div className={'news-list-addon'}>
                    <Button name={"Voir tous les articles"} link={parentLink}/>
                </div><br/>
            </div>
        </section>
    );
};

export const StruturedData = ({article, pageContext}) => {
    return (
        <>
            <StruturedDataArticle article={article} pageContext={pageContext}/>
            <StruturedDataBreadcrumb article={article} pageContext={pageContext}/>
            <StructuredFAQData article={article} pageContext={pageContext}/>
        </>
    );
};


const StruturedDataArticle = ({article, pageContext}) => {
    let structuredData = {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        'mainEntityOfPage': {
            '@type': 'WebPage',
            '@id': `https://maisondunet.com/${article.fields.path}`,
        },
        'headline': article.frontmatter.title,
        'image': [
            `https://maisondunet.com/${article.frontmatter.thumbnail}`,
        ],
        'datePublished': article.frontmatter.rawDate,
        'author': {
            '@type': 'Person',
            'name': article.frontmatter.author,
        },
        'publisher': {
            '@type': 'Organization',
            '@id': 'https://maisondunet.com/',
        },
    };
    structuredData = {'__html': JSON.stringify(structuredData)};
    return (
        <script type="application/ld+json"
                dangerouslySetInnerHTML={structuredData}/>
    );
};

const StructuredFAQData = ({article, pageContext}) => {
    if(!article.frontmatter.faq)
        return (<></>);

    let structuredData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        'mainEntity': article.frontmatter.faq.map(v => {
            return {
                "@type": "Question",
                "name": v.question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": v.response
                }
            }
        })
    };
    structuredData = {'__html': JSON.stringify(structuredData)};
    return (
        <script type="application/ld+json"
                dangerouslySetInnerHTML={structuredData}/>
    );
};

const StruturedDataBreadcrumb = ({article, pageContext}) => {
    let mainCat = article.frontmatter.mainCategory ??
        article.fields.categories[0] ?? '';
    let structuredData = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': [
            {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Actualité Digitale',
                'item': `${pageContext.siteMetadata.siteUrl}/blog/`,
            }, {
                '@type': 'ListItem',
                'position': 2,
                'name': "#" + mainCat,
                'item': `${pageContext.siteMetadata.siteUrl}/blog/${mainCat}`,
            }, {
                '@type': 'ListItem',
                'position': 3,
                'name': article.frontmatter.title,
            }],
    };
    structuredData = {'__html': JSON.stringify(structuredData)};
    return (
        <script type="application/ld+json"
                dangerouslySetInnerHTML={structuredData}/>
    );
};
