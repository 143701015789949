import React from "react"
import {ArticleHeadingComponent} from "../components/article-view";
import {ArticleBodyComponent} from "../components/article-view";
import {ArticleSocialLinksComponent} from "../components/article-view";
import {ArticleRelatedComponent} from "../components/article-view";
import {StruturedData} from "../components/article-view";
import {buildSeo} from "../../utils/seo";

const ArticlePage = ({ pageContext }) => {
    const { articleNode } = pageContext
    const articleNodeContent = articleNode.node;

    return (
        <div className={"page-content blog"}>
            {buildSeo({
                metaTitle: articleNodeContent.frontmatter.title + " - Blog de Loris Pinna",
                title: articleNodeContent.frontmatter.title,
                description: articleNodeContent.frontmatter.metaDescription
            })}
            <ArticleHeadingComponent article={articleNodeContent} />
            <ArticleBodyComponent article={articleNodeContent} />
            <ArticleSocialLinksComponent
                article={articleNodeContent}
                pageContext={pageContext}
            />
            <ArticleRelatedComponent article={articleNodeContent} pageContext={pageContext}/>
            <StruturedData article={articleNodeContent} pageContext={pageContext} />
        </div>
    )
}
export default ArticlePage
